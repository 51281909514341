footer {
  padding: 25px 0;
  font-size: 18px;

  a {
    transition: ease all .3s;
    text-decoration: none;
    color: #FFFFFF;

    &:hover {
      color: #03852c;
    }
  }
}