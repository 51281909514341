[data-ease-class] {
  opacity: 0;
}

.animateStyleMedium {
  animation: translateY30 1.2s ease-out 0s 1 normal both;
}

.animateStyleSlow {
  animation: translateY30 1.4s ease-out 0s 1 normal both;
}

.animateOpacityMedium {
  animation: opacityAnim 1.2s ease-out 0s 1 normal both;
}

.animateStyleFast {
  animation: translateY30 .7s ease-out 0s 1 normal both;
}

@keyframes translateY30 {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes opacityAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  background-color: #000000;
  color: #FFFFFF;
  font-size: 24px;
}

.primary-title {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  font-size: 50px;
  text-transform: uppercase;
  margin-bottom: 25px;

  .small-info {
    font-family: 'Nothing You Could Do', cursive;
    font-size: 30px;
    line-height: 50px;
    color: #03852c;
    text-transform: none;
  }
}

.btn-rounded {
  border-radius: 30px;
  background-color: #03852c;
  color: #FFFFFF;
  padding: 15px 15px;
  transition: ease alll .3s;

  &:hover {
    background-color: darken(#03852c, 10%);
    color: #FFFFFF;
  }
}

.min-width-200 {
  min-width: 200px;
}

.frame-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 25px 0;
  min-height: 100vh;
  background: #000000;

  @include responsive(min-md) {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
  }
}

.parallax-item {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 50vh;
}

.mb-30 {
  margin-bottom: 30px;
}

.px-15 {
  @include responsive(min-md) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.logo-animation {
  background-color: #FBFAF5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
}